<script setup>
import { Icon } from '@vicons/utils';
import { Facebook, LinkedinIn, Github, } from '@vicons/fa';

function openSite(site) {
    window.open(site, '_blank');
}
</script>

<template>
    <div class="social-network">
        <div class="social-media social-media-orientation social-media-placement">
            <ul v-scrollanimation class="social-media-list">
                <li>
                    <div @click="openSite('https://www.facebook.com/ibraahim6/')" class="a-link">
                        <div class="text-size-40px">
                            <Icon><Facebook /></Icon>
                        </div>
                        <span class="icon-title">Facebook</span>
                    </div>
                </li>
                
                <li>
                    <div @click="openSite('https://www.linkedin.com/in/ibraahim6/')" class="a-link">
                        <div class="text-size-40px">
                            <Icon><LinkedinIn /></Icon>
                        </div>
                        <span class="icon-title">LinkedIn</span>
                    </div>
                </li>
                <li>
                    <div @click="openSite('https://github.com/ibraahim6')" class="a-link">
                        <div class="text-size-40px">
                            <Icon><Github /></Icon>
                        </div>
                        <span class="icon-title">GitHub</span>
                    </div>
                </li>
                
                
            </ul>
        </div>
        <div v-scrollanimation class="email-media social-media-orientation social-media-placement">
            <div class="email-media-link">
                <div class="mail-link" @click="$router.push('/contact')">eng.ibraahim6@gmail.com</div>
            </div>
        </div>
    </div>
</template>
