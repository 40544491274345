
export const socialMediaLinks = [{
    icon: `<i class='bx bxl-facebook' ></i>`,
    title: "Facebook",
    link: 'https://www.facebook.com/ibraahim6/',
},
{
    icon: `<i class='bx bxl-linkedin' ></i>`,
    title: "LinkedIn",
    link: 'https://www.linkedin.com/in/ibraahim6/'
},
{
    icon: `<i class='bx bxl-github' ></i>`,
    title: "GitHub",
    link: 'https://github.com/ibraahim6'
},

];

export const email = "eng.ibraahim6@gmail.com";