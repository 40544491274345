import { Html5 } from '@vicons/fa';
import { Javascript16Filled, AppFolder24Filled } from '@vicons/fluent';
import {  html5, nuxt, sass, tailwind, vue,vuetify,bootstrapVue } from '../../assets/SVG_ICONS';

export const skillList = [];

export const mySkills = [
    {
        icon: Javascript16Filled,
        tech: 'JavaScript',
        des: 'It’s my main language and I know most of it. I focus on building single-page apps with attention to code quality and best practices.',
        yearStarted: 2020,
        tools: [
            {
                icon: vue,
                text: 'Vue',
                des: `Vue.js is an open-source model–view–viewmodel front end JavaScript framework for building user interfaces and single-page applications. It was created by Evan You, and is maintained by him and the rest of the active core team members. <a href="https://vuejs.org/" target="_blank" alt="Go To VueJS">Go To VueJS</a>`,
            },
            {
                icon: nuxt,
                text: 'Nuxt JS',
                des: `Build your next Vue.js application with confidence using NuxtJS. An open source framework making web development simple and powerful. <a href="https://nuxtjs.org/" target="_blank" alt="go to Nuxtjs">Go To NuxtJS</a>`,
            },
        ],
    },
    {
        icon: AppFolder24Filled,
        tech: 'UI Frameworks',
        des: 'There must be knowledge of UI Frameworks and building sites using advanced technologies from UI Frameworks, so I chose to learn new technologies to make my work more advanced.',
        yearStarted: 2020,
        tools: [
            {
                icon: vuetify,
                text: 'Vuetify',
                des: `Vuetify is a no design skills required UI Framework with beautifully handcrafted Vue Components. <a href="https://vuetifyjs.com/en/" target="_blank" alt="Go To Vuetify">Go To Vuetify</a>`,
            },
            {
                icon: bootstrapVue,
                text: 'Bootstrap Vue',
                des: `with BootstrapVue you can build responsive, mobile-first, and ARIA accessible projects on the web using Vue.js and the world's most popular front-end CSS library — Bootstrap v4. <a href="https://bootstrap-vue.org/" target="_blank" alt="go to BootstrapVue">Go To BootstrapVue</a>`,
            },
        ],
    },
    {
        icon: Html5,
        tech: 'HTML/CSS',
        des: 'I write semantic markup with accessibility in mind. I keep my CSS manageable and also slowly following BEM conventions/rules.',
        yearStarted: 2019,
        tools: [
            {
                icon: tailwind,
                text: 'tailwindcss',
                des: `A utility-first CSS framework packed with classes like flex, pt-4, text-center and rotate-90 that can be composed to build any design, directly in your markup.`,
            },
            {
                icon: sass,
                text: 'SASS',
                des: `CSS with superpowers. Sass is the most mature, stable, and powerful professional grade CSS extension language in the world.`,
            },
            {
                icon: html5,
                text: 'html5',
                des: `HTML5 is a markup language used for structuring and presenting content on the World Wide Web. It is the fifth and last major HTML version that is a World Wide Web Consortium recommendation. The current specification is known as the HTML Living Standard.`,
            },
        ],
    },
];
